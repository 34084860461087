import React from 'react';
import styles from './ChosenVanSize.styles';
import { css } from 'aphrodite';
import Utils from '../../../library/utils';

const liftingPowers = {
  0: 'No help loading / unloading',
  1: 'Driver helps with loading / unloading',
  2: 'Driver + 1 JJD Man helps load and unload',
  3: 'Driver + 2 JJD Man help load and unload'
};

const ChosenVanSize = (props) => (
  <div className={css(styles.root)}>

    <div className={css(styles.topPrice)}>
      {Utils.getPoundsAndPennies(props.initialTotal)}
    </div>

    <div className={css(styles.content)}>

      <div>
        {props.vanSize && <img className={css(styles.vanImg)} src={require(`../../../assets/vans/${props.vanSize.toLowerCase()}-realistic.png`)} />}
      </div>

      <div className={css(styles.chosenVanDetails)}>
        <div style={{marginBottom: 4}}>{props.vanSize} Van</div>
        <div>{liftingPowers[props.liftingPowerIndex]}</div>
      </div>

      <div className={css(styles.price)}>
        {Utils.getPoundsAndPennies(props.initialTotal)}
      </div>

    </div>
  </div>
);

export default ChosenVanSize;
