import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    width: '100%'
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto'
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1px solid whitesmoke',
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    overflow: 'hidden',
    boxShadow: '0 16px 40px rgba(0,0,0,0.08)'
  },
  input: {
    flex: 1,
    padding: 24,
    border: 'none',
    outline: 'none',
    color: COLORS.offBlack,
    fontWeight: 'bold',
    fontSize: 14
  },
  mobileNumberInput: {
    borderTop: '1px solid whitesmoke',
  },
  lastNameInput: {
    borderLeft: '1px solid whitesmoke',
    [QUERIES.mobile]: {
      borderLeft: 'none',
      borderTop: '1px solid whitesmoke',
    }
  },
  stripeInputContainer: {
    padding: 24,
    border: '1px solid whitesmoke',
    borderTop: 'none',
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    boxShadow: '0 16px 40px rgba(0,0,0,0.08)',
    background: 'white'
  }
});

export default styles;
