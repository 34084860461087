import React from 'react';
import styles from './EasyBooking.styles';
import { css } from 'aphrodite';
import PrimaryButton from '../../../components/buttons/PrimaryButton/PrimaryButton';
import COLORS from '../../../styles/colors';
import {Link} from 'gatsby';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChosenVanSize from '../ChosenVanSize/ChosenVanSize';
import ChosenRoute from '../ChosenRoute/ChosenRoute';
import Utils from '../../../library/utils';
import ChosenArrivalTime from '../ChosenArrivalTime/ChosenArrivalTime';
import EasyBookingPaymentContainer from '../EasyBookingPayment/EasyBookingPaymentContainer';
import RouteDurationAndCashToPayDriver from '../RouteDurationAndCashToPayDriver/RouteDurationAndCashToPayDriver';
import { Elements, StripeProvider } from 'react-stripe-elements';

const EasyBookingActions = (props) => (
  <div className={css(styles.root)}>

    <Link to={'/'} className={css(styles.logoContainer)}>
      <img className={css(styles.logo)} src={require('../../../assets/images/logo-green.svg')}/>
    </Link>

    <div className={css(styles.content)}>

      {props.loading && <div style={{padding: 64, textAlign: 'center'}}>
        <CircularProgress thickness={10} size={20} className={css(styles.progress)} />
      </div>}

      {(!props.loading && props.emailBookingRefError) && <div style={{textAlign: 'center'}}>
        <div style={{paddingTop: 48, paddingBottom: 16, fontSize: 32}}>🙁</div>
        <div style={{paddingBottom: 48, color: COLORS.lightBlack, fontWeight: 'bold', fontSize: 14}}>{props.emailBookingRefError}</div>
        <PrimaryButton large={true} title={'Create a new booking'} to={'/book'} />
      </div>}

      {(!props.loading && !props.emailBookingRefError && props.allDetailsValid) && <div className={css(styles.bookingDetailsContainer)}>
        <ChosenVanSize vanSize={props.emailBookingRefDetails.size} liftingPowerIndex={props.emailBookingRefDetails.liftingPowerIndex} initialTotal={props.emailBookingRefDetails.initialTotal} />

        <ChosenArrivalTime time={props.emailBookingRefDetails.pickup} collectionRange={props.emailBookingRefDetails.collectionRange} />

        <ChosenRoute
          pickupAddress={props.emailBookingRefDetails.pickupAddress}
          additionalStops={props.emailBookingRefDetails.additionalStops}
          dropoffAddress={props.emailBookingRefDetails.dropoffAddress}
        />

        <RouteDurationAndCashToPayDriver
          reward={(props.emailBookingRefDetails.initialTotal - props.emailBookingRefDetails.deposit)}
          duration={props.emailBookingRefDetails.duration}
          distance={props.emailBookingRefDetails.distance}
          requiredTime={props.emailBookingRefDetails.requiredTime}
          rideAlong={props.emailBookingRefDetails.rideAlong}
          fullPayment={props.emailBookingRefDetails.fullPayment}
        />

        {!Utils.isBlank(props.emailBookingRefDetails.description) && <div className={css(styles.description)}>{props.emailBookingRefDetails.description}</div>}

        <p className={css(styles.description)}>
          If the job runs over {props.emailBookingRefDetails.requiredTime} hours, the
          driver may charge for overtime ({Utils.getPoundsAndPennies(props.emailBookingRefDetails.extraChargeValue)} for
          every {props.emailBookingRefDetails.extraChargeMins} minutes) - so do your best to prepare for easy loading.

          You may also need to pay the driver an
          additional {Utils.getPoundsAndPennies(1150)} for congestion charges if you are travelling through central
          London congestion zones

        </p>

        {(typeof window !== 'undefined') && <div className={css(styles.easyBookingPaymentContainer)}>
          <StripeProvider apiKey={props.stripeApiKey}>
            <Elements>
              <EasyBookingPaymentContainer bookingRef={props.bookingRef}/>
            </Elements>
          </StripeProvider>
        </div>}

        <div className={css(styles.modifyBookingLink)} onClick={props.modifyEasyBooking}>Need to change anything?</div>

      </div>}

    </div>
  </div>
);

export default EasyBookingActions;
