import React from 'react';
import styles from './ChosenArrivalTime.styles';
import { css } from 'aphrodite';
import DateIcon from '@material-ui/icons/CalendarTodayRounded';
import TimeIcon from '@material-ui/icons/TimerRounded';

const moment = require('moment');

const ChosenArrivalTime = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>

      <div className={css(styles.item)}>
        <div className={css(styles.label)}>Date</div>
        <DateIcon className={css(styles.icon)} />
        <div className={css(styles.timeDetails)}>
          {moment(props.time).format('dddd, DD MMMM YYYY')}
        </div>
      </div>

      <div className={css(styles.item)}>
        <div className={css(styles.label)}>Arriving</div>
        <TimeIcon className={css(styles.icon)} />
        <div className={css(styles.timeDetails)}>
          {moment(props.time).format('HH:mm A')} - {moment(props.time).add(props.collectionRange, 'hours').format('HH:mm A')}
        </div>
      </div>

    </div>
  </div>
);

export default ChosenArrivalTime;
