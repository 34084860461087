import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    paddingTop: 16,
    paddingBottom: 16
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto'
  },
  item: {
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: 12,
    fontSize: 16,
    color: COLORS.lightBlack
  },
  timeDetails: {
    fontSize: 14,
    fontWeight: 'bold',
    color: COLORS.lightBlack
  },
  label: {
    width: 62,
    height: 24,
    fontSize: 10,
    paddingRight: 12,
    fontWeight: 'bold',
    // background: COLORS.kgGreen,
    color: COLORS.paperText,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'right',
    [QUERIES.desktop]:{
      width: 88
    }
  }
});

export default styles;
