import React from 'react';
import Layout from '../containers/gatsby/layout';
import EasyBookingContainer from '../containers/easyBooking/EasyBooking/EasyBookingContainer';

const EasyBookingPage = (props) => (
  <Layout hideHeader={true} hideFooter={true} location={props.location}>
    <EasyBookingContainer location={props.location} />
  </Layout>
);

export default EasyBookingPage;
