import React, { Component } from 'react';
import EasyBookingPayment from './EasyBookingPayment';
import {connect} from 'react-redux';
import { easyBookingPaymentError, makeEasyBooking } from './EasyBookingPaymentActions';
import Utils from '../../../library/utils';
import { injectStripe } from 'react-stripe-elements';
import { setFirstName, setLastName, setMobileNumber } from '../../../actions/UserDetailsActions';
import { navigate } from 'gatsby';
import StripeAddCard from '../../bookingStep4/StripeAddCard/StripeAddCard';

class EasyBookingPaymentContainer extends Component {

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      firstName: '',
      lastName: '',
      mobileNumber: '',
      stripeToken: null,
      loading: false,
      error: null
    }
  }

  componentDidMount() {
    this.setState({
      firstName: this.props.userDetails.firstName,
      lastName: this.props.userDetails.lastName,
      mobileNumber: this.props.userDetails.mobileNumber
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.easyBooking.paymentError && !this.props.easyBooking.paymentError) {
      this.setState({loading: false, error: nextProps.easyBooking.paymentError})
    }

    if (nextProps.easyBooking.paymentSuccess && !this.props.easyBooking.paymentSuccess) {
      this.setState({loading: false});
      navigate('/easy-booking-success')
    }

    if (nextProps.easyBooking.requiresAction && !this.props.easyBooking.requiresAction) {

      // payment_intent_client_secret


      this.props.stripe.handleCardAction(
        nextProps.easyBooking.payment_intent_client_secret // response.payment_intent_client_secret
      ).then((resp) => {

        if (resp.error) {

          // Show error in payment form
          this.props.easyBookingPaymentError(resp.error.message);

        } else {
          // The card action has been handled
          // The PaymentIntent can be confirmed again on the server
          this.props.makeEasyBooking(this.props.bookingRef, null, resp.paymentIntent.id);
        }
      });

    }
  }

  async submit(ev) {

    this.setState({
      loading: true
    });

    this.props.stripe
      .createPaymentMethod('card', StripeAddCard, {
        billing_details: {
          name: `${this.props.userDetails.firstName} ${this.props.userDetails.lastName}`,
          email: this.props.userDetails.email,
          phone: this.props.userDetails.mobileNumber
        }
      })
      .then((resp) => {

        if (resp.error) {
          this.setState({
            loading: false,
            error: resp.error.message
          });
        }

        if (resp.paymentMethod) {

          // console.log('Received Stripe PaymentMethod:', resp.paymentMethod);
          this.props.makeEasyBooking(this.props.bookingRef, resp.paymentMethod.id);

        }
      });
  }

  render() {
    return (
      <EasyBookingPayment
        {...this.props}
        firstName={this.state.firstName}
        lastName={this.state.lastName}
        mobileNumber={this.state.mobileNumber}
        onChangeFirstName={this.onChangeFirstName}
        onChangeLastName={this.onChangeLastName}
        onChangeMobileNumber={this.onChangeMobileNumber}
        disabled={Utils.isBlank(this.state.firstName) || Utils.isBlank(this.state.lastName) || !Utils.isValidMobile(this.state.mobileNumber)}
        loading={this.state.loading}
        error={this.state.error}
        submit={this.submit}
      />
    )
  }

  onChangeFirstName = (e) => {
    this.setState({firstName: e.target.value}, () => {
      this.props.setFirstName(this.state.firstName)
    })
  };

  onChangeLastName = (e) => {
    this.setState({lastName: e.target.value}, () => {
      this.props.setLastName(this.state.lastName)
    })
  };
  onChangeMobileNumber = (e) => {
    this.setState({mobileNumber: e.target.value.replace(/\s/g, '')}, () => {
      if (Utils.isValidMobile(this.state.mobileNumber)) this.props.setMobileNumber(this.state.mobileNumber)
    })
  };

}

const mapStateToProps = (state) => ({
  userDetails: state.userDetails,
  easyBooking: state.easyBooking
});

const mapDispatchToProps = {
  makeEasyBooking: makeEasyBooking,
  setFirstName: setFirstName,
  setLastName: setLastName,
  setMobileNumber: setMobileNumber,
  easyBookingPaymentError: easyBookingPaymentError
};

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(EasyBookingPaymentContainer));
