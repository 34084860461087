import React from 'react';
import styles from './RouteDurationAndCashToPayDriver.styles';
import { css } from 'aphrodite';
import DrivingTimeIcon from '@material-ui/icons/Commute';
import LiftingIcon from '@material-ui/icons/TimelapseRounded';
import CashIcon from '@material-ui/icons/ArrowRightAltRounded';
import TotalTimeIcon from '@material-ui/icons/TimerRounded';
import PeopleOutlineRounded from '@material-ui/icons/PeopleOutlineRounded';
import Utils from '../../../library/utils';

const moment = require('moment');

// reward={props.emailBookingRefDetails.reward}
// duration={props.emailBookingRefDetails.duration}
// distance={props.emailBookingRefDetails.distance}
// requiredTime={props.emailBookingRefDetails.requiredTime}

const RouteDurationAndCashToPayDriver = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>

      <div className={css(styles.item)}>
        <div className={css(styles.label)}>Booked for</div>
        <TotalTimeIcon className={css(styles.icon)} />
        <div className={css(styles.timeDetails)}>
          {props.requiredTime} hours
        </div>
      </div>

      <div className={css(styles.item)}>
        <div className={css(styles.label)}>Journey</div>
        <DrivingTimeIcon className={css(styles.icon)} />
        <div className={css(styles.timeDetails)}>
          {props.duration} minutes driving ({props.distance} miles)
        </div>
      </div>

      <div className={css(styles.item)}>
        <div className={css(styles.label)}>Loading</div>
        <LiftingIcon className={css(styles.icon)} />
        <div className={css(styles.timeDetails)}>
          {props.requiredTime*60 - props.duration} minutes to load / unload the van
        </div>
      </div>

      <div className={css(styles.item)}>
        <div className={css(styles.label)}>Passengers</div>
        <PeopleOutlineRounded className={css(styles.icon)} />
        <div className={css(styles.timeDetails)}>
          {props.rideAlong} Ride-Along passengers
        </div>
      </div>

      <div style={{height: 16}} />

      {!props.fullPayment && <div className={css(styles.item)}>
        <div className={css(styles.label)}>On the day</div>
        <CashIcon className={css(styles.icon)} />
        <div className={css(styles.timeDetails)}>
          Cash to pay driver: {Utils.getPoundsAndPennies(props.reward)}
        </div>
      </div>}

    </div>
  </div>
);

export default RouteDurationAndCashToPayDriver;
