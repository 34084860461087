import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    width: '100%'
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row'
  },
  vanImg: {
    height: 120
  },
  chosenVanDetails: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 16,
    fontWeight: 'bold',
    color: COLORS.lightBlack,
    fontSize: 14
  },
  price: {
    height: 120,
    fontSize: 28,
    paddingRight: 12,
    fontWeight: 'bold',
    color: COLORS.kgGreen,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [QUERIES.mobile]: {
      display: 'none'
    }
  },
  topPrice: {
    height: 112,
    paddingTop: 16,
    fontSize: 36,
    fontWeight: 'bold',
    color: COLORS.kgGreen,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [QUERIES.desktop]: {
      display: 'none'
    }
  }
});

export default styles;
