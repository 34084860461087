import React from 'react';
import styles from './EasyBookingPayment.styles';
import { css } from 'aphrodite';
import PrimaryButton from '../../../components/buttons/PrimaryButton/PrimaryButton';
import { CardElement, injectStripe } from 'react-stripe-elements';
import STYLES from '../../../styles/shared';
import Utils from '../../../library/utils';
import COLORS from '../../../styles/colors';

const EasyBookingPayment = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>

      <div className={css(styles.userInfo)}>
        <div style={{display: 'flex', flexDirection: 'row', flex: 1, flexWrap: 'wrap'}}>
          <input className={css(styles.input)} placeholder={'First name'} value={props.firstName} onChange={props.onChangeFirstName} />
          <input className={css(styles.input, styles.lastNameInput)} placeholder={'Last name'} value={props.lastName} onChange={props.onChangeLastName} />
        </div>
        <input className={css(styles.input, styles.mobileNumberInput)} placeholder={'Mobile number'} value={props.mobileNumber} onChange={props.onChangeMobileNumber} />
      </div>

      <div className={css(styles.stripeInputContainer)}>
        <CardElement className={'stripe-card-element'} style={{base: {fontSize: '16px'}}} />
      </div>

      <div className={css(styles.priceBreakdownContainer)}>
        <div className={css(STYLES.fullRow, styles.cardRow)} style={{paddingTop: 32}}>
          <div style={{flex: 1}}/>
        </div>
        <div className={css(STYLES.fullRow)} style={{paddingLeft: 16, paddingRight: 16}}>
          <div style={{fontWeight: 'bold', fontSize: 18}}>{props.easyBooking.details.fullPayment? 'Total' : 'Deposit'}</div>
          <div style={{flex: 1}}/>
          <div style={{
            fontWeight: 'bold',
            fontSize: 18,
            paddingRight: 8
          }}>{Utils.getPoundsAndPennies(props.easyBooking.details.deposit)}</div>
        </div>
      </div>

      <PrimaryButton
        large={true}
        style={{width: '100%', marginTop: 32}}
        title={'Confirm booking'}
        disabled={props.disabled}
        onClick={props.submit}
        loading={props.loading}
      />

      {props.error && <p style={{color: COLORS.hotPink, margin: 0, padding: 16, fontSize: 14, fontWeight: 'bold'}}>{props.error}</p>}

    </div>

  </div>
);

export default injectStripe(EasyBookingPayment);
