import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    padding: 16,
    paddingBottom: 88,
    [QUERIES.mobile]: {
      padding: 0
    }
  },
  logoContainer: {
    margin: 0,
    height: 72,
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    height: 32
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto'
  },
  progress: {
    color: COLORS.kgGreen
  },
  title: {
    fontSize: 18,
    color: COLORS.offBlack,
    margin: 0
  },
  bookingDetailsContainer: {
    width: '100%',
    maxWidth: 640,
    boxShadow: '0 16px 40px rgba(0,0,0,0.12)',
    padding: 32,
    paddingTop: 0,
    margin: '0 auto',
    marginTop: 32,
    borderRadius: 4,
    [QUERIES.mobile]: {
      marginTop: 0,
      padding: 16,
      paddingBottom: 32
    }
  },
  description: {
    padding: 16,
    fontSize: 14,
    color: COLORS.lightBlack,
    fontWeight: 'bold'
  },
  easyBookingPaymentContainer: {
    paddingTop: 16
  },
  modifyBookingLink: {
    padding: '32px 16px 0 16px',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    color: COLORS.lightBlack,
    ':hover': {
      cursor: 'pointer'
    }
  }
});

export default styles;
