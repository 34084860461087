import React from 'react';
import styles from './ChosenRoute.styles';
import { css } from 'aphrodite';
import PickupIcon from '@material-ui/icons/LocationOn';
import AdditionalStopIcon from '@material-ui/icons/ArrowDownwardOutlined';
import DropoffIcon from '@material-ui/icons/HomeRounded';

// number: "66 Gregory Crescent"
// postcode: "SE9 5SA"
// street: ""
// town: "LONDON"

const getFullAddress = (addressObj) => {
  return [addressObj.number, addressObj.street, addressObj.postcode, addressObj.town].filter(val => val && val.length > 0).join(', ');
};

const ChosenRoute = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>

      <div className={css(styles.address)}>
        <div className={css(styles.label)}>Pickup</div>
        <PickupIcon className={css(styles.icon)} />
        <div className={css(styles.addressName)}>
          {getFullAddress(props.pickupAddress)}
        </div>
      </div>

      {props.additionalStops && props.additionalStops.map((stop, index) => (
        <div key={index} className={css(styles.address)}>
          <div className={css(styles.label)}>{`Stop ${index + 1}`}</div>
          <AdditionalStopIcon className={css(styles.icon)} />
          <div className={css(styles.addressName)}>
            {getFullAddress(stop)}
          </div>
        </div>
      ))}

      <div className={css(styles.address)}>
        <div className={css(styles.label)}>Dropoff</div>
        <DropoffIcon className={css(styles.icon)} />
        <div className={css(styles.addressName)}>
          {getFullAddress(props.dropoffAddress)}
        </div>
      </div>

    </div>
  </div>
);

export default ChosenRoute;
