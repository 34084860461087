import React, { Component } from "react";
import EasyBookingActions from "./EasyBooking";
import { connect } from "react-redux";
import {
  getEmailBookingRefDetails,
  populateBookingFromEasyBooking,
} from "./EasyBookingActions";
import { navigate } from "gatsby";

const qs = require("query-string");

class EasyBookingContainer extends Component {
  constructor(props) {
    super(props);
    this.stripeApiKey =
      `${process.env.GATSBY_API_URL}` === "https://api.jjdvans.com/"
        ? "pk_live_PL3BEeH12WSDR5RDswd9C2kx00ifdZgJ77"
        : "pk_test_FxWnxNsFVLXVBiHuPeajaF2O005Xz60Iyz";
    this.state = {
      loading: true,
      allDetailsValid: false,
      bookingRef: null,
    };

    this.loadingTimeout = null;

    // http://localhost:8000/easy-booking?emailBookingRef=fee79ba0-8855-11e9-83fd-fd9f474435f0
  }

  componentDidMount() {
    const emailBookingRef = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).emailBookingRef;
    this.setState({
      bookingRef: emailBookingRef,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth._csrf && this.props.auth._csrf === null) {
      this.props.getEmailBookingRefDetails(this.state.bookingRef);
    }

    if (
      nextProps.emailBookingDetailsObtainedSuccessfully !==
      this.props.emailBookingDetailsObtainedSuccessfully
    )
      this.setState({
        loading: false,
        allDetailsValid: true,
      });

    if (nextProps.emailBookingRefError !== this.props.emailBookingRefError)
      this.setState({ loading: false });
  }

  modifyEasyBooking = () => {
    this.props.populateBookingFromEasyBooking();
    navigate("/book");
  };

  render() {
    return (
      <EasyBookingActions
        {...this.props}
        stripeApiKey={this.stripeApiKey}
        loading={this.state.loading}
        allDetailsValid={this.state.allDetailsValid}
        bookingRef={this.state.bookingRef}
        modifyEasyBooking={this.modifyEasyBooking}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.userDetails,
  emailBookingRefDetails: state.easyBooking.details,
  emailBookingRefError: state.easyBooking.error,
  emailBookingDetailsObtainedSuccessfully: state.easyBooking.obtainedSuccess,
  auth: state.auth,
});

const mapDispatchToProps = {
  getEmailBookingRefDetails: getEmailBookingRefDetails,
  populateBookingFromEasyBooking: populateBookingFromEasyBooking,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EasyBookingContainer);
